@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,500&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Avenir", sans-serif;
}

body {
  background: #ffffff;
}

.no-global-styles div {
  /* background-color: #0000F0; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-container {
  display: flex !important;
  flex-direction: row-reverse !important;
  align-items: center;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__view-calendar-icon input {
  padding: 18px 30px !important;
}

.react-datepicker__calendar-icon {
  right: 23px;
}
/* react-quill css start  */
.ql-toolbar.ql-snow {
  border-color: #c3c1df !important;
  border-left: 0px !important;
  border-right: 0px !important;
  background-color: #fafafe !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.ql-container.ql-snow {
  border: none !important;
  padding-bottom: 58px;
}

.ql-editor img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.ql-editor a img {
  display: inline-block;
}

.css-wmy1p7-ReactDropdownSelect:focus,
.css-wmy1p7-ReactDropdownSelect:focus-within {
  box-shadow: none !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding-left: 0px !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  z-index: 10;
  bottom: 160%;
  left: -570%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
}

.tooltip .tooltiptext::before {
  content: "";
  position: absolute;
  left: 50%;
  z-index: 1;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip2 {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip2 .tooltiptext2 {
  visibility: hidden;
  position: absolute;
  z-index: 10;
  bottom: 160%;
  right: -52%;
  margin-left: -60px;
}

.tooltip2 .tooltiptext2::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 2%;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
}

.tooltip2 .tooltiptext2::before {
  content: "";
  position: absolute;
  z-index: 1;
  right: 2%;
  margin-left: -5px;
  border-width: 12px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltip2:hover .tooltiptext2 {
  visibility: visible;
}

.arrow {
  background-image: url(assets/svg/arrow.svg);
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

@media only screen and (max-width: 1279px) {
  .tooltip .tooltiptext {
    left: auto;
    right: -52%;
  }

  .tooltip .tooltiptext::after {
    right: 2%;
    left: auto;
  }

  .tooltip .tooltiptext::before {
    right: 2%;
    left: auto;
  }
}

@media only screen and (max-width: 320px) {
  .tooltip .tooltiptext {
    max-width: 270px;
  }

  .tooltip2 .tooltiptext2 {
    max-width: 270px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@media print {
  body {
    margin: 0 !important;
  }
}

.main-container {
  --ckeditor5-preview-sidebar-width: 270px;
  /* width: fit-content; */
  margin-left: auto;
  margin-right: auto;
}

.main-container .presence {
  margin-bottom: 8px;
}

.ck-content {
  line-height: 1.6;
  word-break: break-word;
  height: 400px;
}

.editor-container__editor-wrapper {
  display: flex;
  width: fit-content;
}

.editor-container_include-outline
  .editor-container__editor
  .ck.ck-editor__editable {
  margin-left: 0;
}

.editor-container_include-annotations
  .editor-container__editor
  .ck.ck-editor__editable {
  margin-right: 0;
}

/* .editor-container_classic-editor .editor-container__editor {
	min-width: 795px;
	max-width: 795px;
} */

.editor-container__sidebar {
  min-width: var(--ckeditor5-preview-sidebar-width);
  max-width: var(--ckeditor5-preview-sidebar-width);
  margin-top: 28px;
  margin-left: 10px;
  margin-right: 10px;
}

.revision-history {
  display: none;
}

.revision-history__wrapper {
  display: flex;
}

.revision-history__wrapper .ck.ck-editor {
  margin: 0;
  width: 795px;
}

.revision-history__wrapper .revision-history__sidebar {
  border: 1px solid var(--ck-color-base-border);
  border-left: 0;
  width: var(--ckeditor5-preview-sidebar-width);
  min-height: 100%;
}

.revision-history__wrapper
  .revision-history__sidebar
  .ck-revision-history-sidebar {
  height: 100%;
}

.header-element {
  padding-right: 14px;
}

.no-global-styles .header-element {
  padding-right: 0px;
}

.header-element:last-child {
  padding-right: 20px;
}

.no-global-styles .header-element:last-child {
  padding-right: 0px;
}

.header-element:first-child {
  padding-right: 20px;
}

.no-global-styles .header-element:first-child  {
  padding-right: 0px;
}


/* .ck-content li {
  list-style-position: inside;
} */

p::marker {
  content: none;
}

.ck-editor__editable .ck-list-bogus-paragraph {
  display: inline-block !important;
}

.ck .ck-link_selected {
  background: var(--ck-color-link-selected-background);
  color: #0000f0;
  cursor: pointer;
}

a {
  color: #0000f0;
  text-decoration: inherit;
  cursor: pointer;
}

.ck.ck-dropdown .ck-dropdown__arrow {
  z-index: 0 !important;
}



ol {
  list-style: decimal;
  padding-left: 30px;
}


.no-global-styles ol{
  padding-left: 20px;
}


ol ol {
  list-style: lower-latin;
  padding-left: 10px;
}

.no-global-styles ol ol{
  padding-left: 15px;
}

ol ol ol {
  list-style: lower-roman;
  padding-left: 20px;
}

.no-global-styles ol ol ol{
  padding-left: 20px;
}

ol ol ol ol {
  list-style: upper-latin;
}

.no-global-styles ol ol ol ol{
  padding-left: 20px;
}


ol ol ol ol ol {
  list-style: upper-roman;
}

ul {
  list-style: revert !important;
  padding-left: 30px !important;
}

.no-global-styles ul{
  padding-left: 10px !important;

}


li {
  margin-block: 10px;
}

.no-global-styles ul{
  margin-block: 0px;

}

.ck.ck-editor__main p {
  font-size: 1em;
  line-height: 1.6em;
  padding-top: 0.2em;
}

.ck.ck-editor__main h1 {
  line-height: 1.2em;
  margin-bottom: 0.4em;
  font-size: 2.3em;
  overflow-wrap: break-word;
  font-weight: bold;
}

.ck.ck-editor__main h2 {
  line-height: 1.2em;
  margin-bottom: 0.4em;
  font-size: 1.84em;
  overflow-wrap: break-word;
  font-weight: bold;
}

.ck.ck-editor__main h3 {
  line-height: 1.2em;
  margin-bottom: 0.4em;
  font-size: 1.48em;
  overflow-wrap: break-word;
  font-weight: bold;
}

.ck.ck-editor__main h4 {
  line-height: 1.2em;
  margin-bottom: 0.4em;
  font-size: 1.22em;
  overflow-wrap: break-word;
  font-weight: bold;
}

.ck.ck-editor__main h5 {
  line-height: 1.2em;
  margin-bottom: 0.4em;
  font-size: 1.06em;
  overflow-wrap: break-word;
  font-weight: bold;
}

.ck.ck-editor__main h6 {
  line-height: 1.2em;
  margin-bottom: 0.4em;
  font-size: 1em;
  overflow-wrap: break-word;
  font-weight: bold;
}

.ck-content code {
  background-color: transparent;
}

.ql-snow .ql-editor code{
  padding: 0;
}

.ql-snow .ql-editor code,
.ql-snow .ql-editor pre {
  background-color: transparent;
  border-radius: 0px;
}

.marker-red {
  background-color: rgb(231, 19, 19);
}

/* UTILITIES */
.tableShadow {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}

.heading-bold {
  @apply text-[18px] font-semibold;
}

.heading-regular-grey {
  @apply text-[#696868] dark:text-white text-base font-normal;
}

.text-regular {
  @apply text-[#393C51]  dark:text-white  font-normal text-base;
}

.text-medium {
  @apply text-[#393C51]  dark:text-white  font-medium;
}

.text-regular-bold {
  @apply text-secondary dark:text-white font-bold text-base;
}

.table-container {
  @apply border border-[#DCDBEE] rounded-md overflow-hidden;
}

.button-action {
  @apply rounded-full p-2 border border-[#E6E6F9] bg-b-secondary-light cursor-pointer;
}
